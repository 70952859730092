<template>
  <div class="category-list-common">
    <div class="gmo">
      <picture>
        <source media="(min-width:768px)" srcset="../../assets/images/gmo-pc.png">
        <source media="(max-width:499px)" srcset="../../assets/images/gmo-sp.png">
        <img src="../../assets/images/gmo-pc.png" alt="マイページの見方" class="switching" @click="redirectToSurveyWallPage()">
      </picture>
    </div>

    <!-- header category -->
    <div class="header-category">
      <div class="has-bg">
        <!-- sidebar on sp -->
        <button @click="showSearchModal"
                v-if="!checkWebview()"
                class="show-sidebar">プログラムをさがす<img src="../../assets/images/icon/arrow-down-black.png" alt="icon"/>
        </button>

        <!-- title category -->
        <div class="title-category">
          <p v-if="titleCate">{{ titleCate }}</p><span class="total-cate pc">{{ displayAdsAll ? $store.state.advertises.pagination.total
          : $store.state.category.pagination.total }}件</span>
        </div>
      </div>

      <!-- sort category -->
      <div class="sort-category">
        <!-- label total category on sp -->
        <span class="total-cate">{{ displayAdsAll ? $store.state.advertises.pagination.total
          : $store.state.category.pagination.total }}件</span>

        <div class="wrap-select">
          <select v-model="selectSort"
                  @change="sortPage">
            <option value="" disabled>選択してください</option>
            <option value="0" class="select-dropdown__list-item">新着順</option>
            <option value="1" class="select-dropdown__list-item">ポイントが高い順</option>
            <option value="2" class="select-dropdown__list-item">締め切りが近い順</option>
          </select>
          <img v-if="typeColor" src="../../assets/images/icon/arrow-down-pink.png" alt="">
          <img v-else src="../../assets/images/icon/arrow-down.png" alt="">
        </div>
      </div>
    </div>

    <!-- list category -->
    <ListCategory :list="displayAdsAll ? advertiseList : categoryList"
                  v-if="displayAdsAll ? advertiseList.length > 0 : categoryList.length > 0"
                  :type-color="typeColor" />

    <!-- NOTE DATA EMPTY -->
    <p class="data-empty" v-else-if="rendered">該当するデータが見つかりませんでした。</p>

    <!-- pagination -->
    <Pagination class="pagination"
                v-if="rendered"
                :total-page="displayAdsAll ? $store.state.advertises.pagination.totalPages
          : $store.state.category.pagination.totalPages"
                @change-page = changePage
                :current-page="displayAdsAll ? $store.state.advertises.pagination.currentPage
          : $store.state.category.pagination.currentPage" />
  </div>
</template>

<script>
import ListCategory from '@/components/ListCategory'
import Pagination from '@/components/Pagination'
import { mapActions, mapState } from 'vuex'
import Common from '@/mixins/common.mixin'
import GMOResearch from '@/mixins/gmoResearch.mixin'

export default {
  name: 'Category',

  components: { Pagination, ListCategory },

  mixins: [Common, GMOResearch],

  props: {
    typeColor: {
      type: String,
      default: null
    },

    titleCate: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState('category', ['categoryList', 'pagination', 'cateID']),
    ...mapState('advertises', ['advertiseList', 'pagination']),

    displayAdsAll () {
      return this.$route.params.id === 'all'
    }
  },
  mounted () {
    if (this.$route.query.level === 'new') {
      // set sort if redirect from btn show more list New in Page Home
      this.selectSort = '0'
      this.params = this.sortParams[this.selectSort]
    } else {
    // reset sort when change category small
      this.selectSort = ''
    }
  },

  data () {
    return {
      params: {
        perPage: 20,
        page: 1,
        filter_advertise_type: 1,
        orderBy: 'publish_start_datetime',
        sortedBy: 'desc'
        // is_opening: 1
      },
      selectSort: '',
      sortParams: [
        {
          perPage: 20,
          page: 1,
          filter_advertise_type: 1,
          orderBy: 'created_at',
          sortedBy: 'desc'
          // is_opening: 1
        },
        {
          perPage: 20,
          page: 1,
          filter_advertise_type: 1,
          order_option: 'point'
          // is_opening: 1
        },
        {
          perPage: 20,
          page: 1,
          filter_advertise_type: 1,
          orderBy: 'publish_end_datetime',
          sortedBy: 'asc'
          // is_opening: 1
        }
      ],
      rendered: false
    }
  },

  methods: {
    ...mapActions('category', ['getListCate']),
    ...mapActions('advertises', ['getListAdvertises']),
    ...mapActions('advertisesMid', ['getListAdvertisesByMid']),

    showSearchModal () {
      this.$store.commit('user/DISPLAY_MODAL', true)
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.fetchList(this.cateID, this.params)
    },

    sortPage () {
      this.sortParams[this.selectSort] = {
        ...this.sortParams[this.selectSort],
        page: this.pageActive || 1
      }
      this.params = this.sortParams[this.selectSort]
      const query = { ...this.$route.query }
      if (query.level === 'new') {
        delete query.level
      }
      this.$router.replace({
        name: 'CategoryList.Category',
        params: this.$route.params,
        query
      }).catch(() => {})
      this.fetchList(this.cateID, this.params)
    },

    // FETCH LIST
    fetchList (id, params = {}) {
      if (this.$route.params.id === 'all') {
        this.getListAdvertises(params)
      } else if (this.$route.query.level === 'mid') {
        this.getListAdvertisesByMid([id, params]).then(result => {
          this.$store.dispatch('category/setListCate', [id, result])
        })
      } else {
        this.getListCate([id, params])
      }

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    // change flag rendered to show text empty if data empty
    pageRender () {
      this.rendered = true
    }
  }

}
</script>

<style lang="scss" scoped>
.data-empty {
  font-weight: bold;
  margin-bottom: 50px;
}

.category-list-common {
  margin-top: 0;
}

.gmo {
  cursor: pointer;
  margin-bottom: 40px;

  @media #{$info-phone} {
    margin: 20px 0;
  }

  img {
    width: 100%;
  }
}
</style>
